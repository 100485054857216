import 'bootstrap/dist/js/bootstrap'
import 'select2/dist/js/select2'
import 'select2/dist/js/i18n/pt-BR'
import 'admin-lte/bower_components/jquery-slimscroll/jquery.slimscroll'
import 'admin-lte/bower_components/fastclick/lib/fastclick'
import 'admin-lte/bower_components/inputmask/dist/jquery.inputmask.bundle'
import 'admin-lte/plugins/iCheck/icheck'
import 'admin-lte/dist/js/adminlte'
import 'jquery-mask-plugin'
import 'bootstrap-datepicker/js/bootstrap-datepicker'
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.pt-BR'
import 'bootstrap-slider/src/js/bootstrap-slider.js'
import 'bootstrap-timepicker/js/bootstrap-timepicker.min.js'
import 'trix'

window.jQuery = window.$ = require('jquery')
