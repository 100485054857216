import Rails from 'rails-ujs'
import * as ActiveStorage from 'activestorage'
import 'cocoon-js'

import '../bootstrap'
import '../../scss/app.scss'
import '../../less/app.less'

Rails.start()
ActiveStorage.start()

function injectSelectAllOptions() {
  $('.select-all').each(function() {
    var parentSelect = $(this);
    var selectAllOption = parentSelect.find("option[value='select-all-option']");

    if (selectAllOption == undefined || selectAllOption.length == 0) {
      var optionSelectAll =
        '<option value="select-all-option" id="select-all-option">Selecionar tudo</option>';

      var optionRemoveAll =
        '<option value="select-none-option" id="select-none-option">Remover tudo</option>';

      parentSelect.prepend(optionSelectAll);
      parentSelect.append(optionRemoveAll);

      parentSelect.change(function() {
        $(this).find('option:selected').each(function () {
          var value = $(this).attr('value');
            switch (value) {
              case 'select-all-option':
                parentSelect.find('option[value="select-all-option"]').remove();
                parentSelect.find('option[value="select-none-option"]').remove();
                parentSelect.find('option').prop('selected', 'selected');
                parentSelect.prepend(optionSelectAll);
                parentSelect.append(optionRemoveAll);
                break;
              case 'select-none-option':
                parentSelect.find('option[value="select-all-option"]').remove();
                parentSelect.find('option[value="select-none-option"]').remove();
                parentSelect.find('option').prop('selected', false);
                parentSelect.prepend(optionSelectAll);
                parentSelect.append(optionRemoveAll);
                break;
            }
        });
      })
    }
  });
}

$(function(){
  var url_states = '/states'
  var url_cities = '/cities'

  $(".select2").select2({
    "language": "pt-BR"
  })

  $('.datepicker').datepicker({
    language: 'pt-BR',
    autoclose: true,
    startDate: '1d',
    format: "dd/mm/yyyy",
    orientation: 'auto bottom'
  })

  $('.timepicker').timepicker({
    minuteStep: 5,
    showSeconds: false,
    showMeridian: false,
    icons: {
      up: 'fa fa-angle-up',
      down: 'fa fa-angle-down'
    }
  })

  $('.box-content-info').slimScroll({
		height: '440px'
  })

  $('.box-slim').slimScroll({
		height: '340px'
	})

  injectSelectAllOptions()

  $('.box-content-tickets').each(function(){
    if(this.clientHeight > 520){
      $(this).slimScroll({
        height: '520px'
      })
    }
  })

  $('.box-content-research').slimScroll({
		height: '250px'
	})

  $('[data-toggle="tooltip"]').tooltip()

  $('.slider').slider({
    tooltip: 'always',
    tooltip_position:'bottom'
  })

  $('.positive').bind('keypress', function(event){
    if(event.charCode == 8 || event.charCode == 0 || event.charCode == 13){
      return null
    } else {
      return event.charCode >= 48 && event.charCode <= 57
    }
  })

  $("[id$='address_attributes_country']").change(function() {
    $("[id$='address_attributes_city_id']").find('option').remove()
    $.get(
      url_states,
      {
        from_country: $(this).val(),
        select_id: '#' + $("[id$='address_attributes_state']").attr('id'),
        attr_name: 'to_s'
      },
      null,
      'script'
    )
  })

  $("[id$='address_attributes_state']").change(function() {
    $.get(
      url_cities,
      {
        from_state: $(this).val(),
        select_id: '#' + $("[id$='address_attributes_city_id']").attr('id')
      },
      null,
      'script'
    )
  })

  $(document).on('change', ':file', function() {
    var input = $(this),
      numFiles = input.get(0).files ? input.get(0).files.length : 1,
      label = input.val().replace(/\\/g, '/').replace(/.*\//, '')
    input.trigger('fileselect', [numFiles, label])
  })

  $(document).ready(function() {
    $(':file').on('fileselect', function(event, numFiles, label) {
      var input = $(this).parents('.input-group').find(':text'),
        log = numFiles > 1 ? numFiles + ' files selected' : label

      if( input.length ) {
        input.val(log)
      }
    })
  })
})
